// src/components/Dashboard.js
import React, { useState } from 'react';
import { logout } from '../utils/auth';
import FramesUpload from './FramesUpload';
import FramesSlider from './FramesSlider';
import BundleUpload from './BundlesSlider';
import ListOrders from './ListOrders';
import Settings from './Settings';
import Popup from './Popup';
import ListTemps from './ListTemps';

const Dashboard = () => {
    const [showFramesUpload, setShowFramesUpload] = useState(false);
    const [showBundleUpload, setShowBundleUpload] = useState(false);
    const [showAddFrame, setShowAddFrame] = useState(false);
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [showListOrders, setShowListOrders] = useState(true);
    const [showSettings, setShowSettings] = useState(false);
    const [showListTemps, setShowListTemps] = useState(false);
    
    const toggleListOrders = () => {
        setShowListOrders(true);
        setShowFramesUpload(false);
        setShowBundleUpload(false);
        setShowAddFrame(false);
        setShowSettings(false);
    };

    const toggleSettings = () => {
        setShowSettings(true);
        setShowListOrders(false);
        setShowFramesUpload(false);
        setShowBundleUpload(false);
        setShowAddFrame(false);
    };

    const toggleListTemps = () => {
        setShowListTemps(true);
        setShowListOrders(false);
        setShowFramesUpload(false);
        setShowBundleUpload(false);
        setShowAddFrame(false);
        setShowSettings(false);
    };

    const handleBundleSelect = (bundle) => {
        setSelectedBundle(bundle);
    };

    const handleLogout = async () => {
        await logout();
        window.location.href = '/';
    };
    const toggleFramesUpload = () => {
        setShowFramesUpload(!showFramesUpload);
        setShowListOrders(false);
        setShowBundleUpload(false);
        setShowAddFrame(true);
        setShowSettings(false);
    };
    const toggleBundleUpload = () => {
        setShowBundleUpload(true);
        setShowFramesUpload(false);
        setShowListOrders(false);
        setShowAddFrame(false);
        setShowSettings(false);
    };
    const toggleAddFrame = () => {
        setShowAddFrame(true);
        setShowFramesUpload(false);
        setShowBundleUpload(false);
        setShowListOrders(false);
        setShowSettings(false);
    };
    const handleFrameSelect = (frame) => {
        // console.log('Selected frame:', frame);
    };
    //get search params
    const urlParams = window ? new URLSearchParams(window.location.search) : null;
    const search = urlParams ? urlParams.get('search') : null;
    const isIl = urlParams ? urlParams.get('site') === 'il' : false;
    return (
        <div className="flex bg-gray-50 min-h-screen relative">
            {!isIl? (
                <a className="absolute top-4 right-4 bg-blue-600 text-white px-2 py-1 rounded-md text-sm hover:bg-blue-700 transition duration-200" style={{right: '100px'}} href="/dashboard?site=il">
                        go to Il site
                </a>
            ) : (
                <a className="absolute top-4 right-4 bg-blue-600 text-white px-2 py-1 rounded-md text-sm hover:bg-blue-700 transition duration-200" style={{right: '100px'}} href="/dashboard?site=global">
                        go to Global site
                </a>
            )}
            {/* Logout Button */}
            <button 
                onClick={handleLogout} 
                className="absolute top-4 right-4 bg-red-600 text-white px-2 py-1 rounded-md text-sm hover:bg-red-700 transition duration-200"
            >
                Logout
            </button>

            {/* Sidebar */}
            <div className="w-64 bg-white shadow-lg min-h-screen">
                <h2 className="text-2xl font-semibold p-6 text-gray-800 border-b">Admin Dashboard</h2>
                
                <div className="flex flex-col">
                    <button 
                        onClick={toggleListOrders} 
                        className={`p-4 text-left hover:bg-gray-100 transition duration-200 ${
                            showListOrders ? 'bg-gray-100 border-l-4 border-blue-600' : ''
                        }`}
                    >
                        Orders
                    </button>
                    
                    <button 
                        onClick={toggleListTemps} 
                        className={`p-4 text-left hover:bg-gray-100 transition duration-200 ${
                            showListTemps ? 'bg-gray-100 border-l-4 border-blue-600' : ''
                        }`}
                    >
                        Temporary Orders
                    </button>
                    
                    <button 
                        onClick={toggleAddFrame} 
                        className={`p-4 text-left hover:bg-gray-100 transition duration-200 ${
                            showAddFrame ? 'bg-gray-100 border-l-4 border-blue-600' : ''
                        }`}
                    >
                        Frames
                    </button>
                    
                    <button 
                        onClick={toggleBundleUpload} 
                        className={`p-4 text-left hover:bg-gray-100 transition duration-200 ${
                            showBundleUpload ? 'bg-gray-100 border-l-4 border-blue-600' : ''
                        }`}
                    >
                        Bundles
                    </button>

                    <button 
                        onClick={toggleSettings} 
                        className={`p-4 text-left hover:bg-gray-100 transition duration-200 ${
                            showSettings ? 'bg-gray-100 border-l-4 border-blue-600' : ''
                        }`}
                    >
                        Settings
                    </button>
                </div>
            </div>

            {/* Main Content Area */}
            <div className="flex-1 px-6 py-4">
                {showAddFrame && (
                    <div className="h-full flex flex-col">
                        {!showFramesUpload && (
                            <div className="mb-6">
                                <button onClick={toggleFramesUpload} className="w-32 bg-gray-900 text-white py-1 rounded-md hover:bg-gray-800 transition duration-200">
                                    Add new frame
                                </button>
                            </div>
                        )}
                        {showFramesUpload && (
                            <Popup
                                triggerText="Upload Frame"
                                isOpen={showFramesUpload}
                                togglePopup={toggleFramesUpload}
                            >
                                <FramesUpload togglePopup={toggleFramesUpload} />
                            </Popup>
                        )}
                        <div className="flex-1">
                            <FramesSlider bundlesContorls={false} onFrameSelect={handleFrameSelect} handleFrameSelect={handleFrameSelect} />
                        </div>
                    </div>
                )}
                
                {showBundleUpload && <BundleUpload selectedBundle={selectedBundle} />}
                {showListOrders && <ListOrders />}
                {showListTemps && <ListTemps />}
                {showSettings && <Settings />}
            </div>
        </div>
    );
};

export default Dashboard;