import React, { useEffect, useState } from 'react';
import { listAllFiles, downloadFile, deleteFile, getFileMetadata } from '../firebase';
import './ListOrders.css';
import Popup from './Popup';
import OrderDetails from './OrderDetails';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useSearchParams } from 'react-router-dom';

const ListOrders = () => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const [searchParams] = useSearchParams();
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    const toggleOrderDetails = (order) => {
        if (order) {
            const fileName = files.find(file => file.content.oid === order.oid)?.name;
            order.order_number = fileName?.split('_order_number_')[0].split('/')[1];
        }
        setPopupContent(order);
        setShowOrderDetails(!showOrderDetails);
    };

    const downloadAllCroppedImages = async (order) => {
      const fileName = files.find(file => file.content.oid === order.oid)?.name;
        let orderNumber = fileName?.split('_order_number_')[0].split('/')[1];
        const zip = new JSZip();
        
        order.toPrint.forEach((item, index) => {
            if (item.data.type === 'bundle') {
                Object.values(item.data.customized_frames || {}).forEach((image, imgIndex) => {
                    zip.file(`${orderNumber}_bundle_${index}_cropped_${imgIndex}.jpg`, fetch(image).then(res => res.blob()));
                });
            } else if (item.data.cropped_image_url) {
                zip.file(`${orderNumber}_${item.data.current_frame_name}_item_${index}_cropped.jpg`, fetch(item.data.cropped_image_url).then(res => res.blob()));
            }
        });

        const zipFile = await zip.generateAsync({ type: 'blob' });
        saveAs(zipFile, `${orderNumber}_cropped_images.zip`);
    };

    const handleDeleteClick = (file) => {
        setDeleteConfirmation(file);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteFile('', deleteConfirmation.name);
            setFiles(files.filter(f => f.name !== deleteConfirmation.name));
            setDeleteConfirmation(null);
        } catch (error) {
            setError(error);
        }
    };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const filesList = await listAllFiles('temp');
        const filesContent = await Promise.all(
            filesList.map(async (file) => {
                const url = await downloadFile('', file);
                const metadata = await getFileMetadata('', file);
                const response = await fetch(url);
                const content = await response.text();
                return { 
                    name: file, 
                    content: JSON.parse(content),
                    createdAt: metadata.timeCreated
                };
            })
        );
        // Sort files by creation date, newest first
        const sortedFiles = filesContent.sort((a, b) => 
            new Date(b.createdAt) - new Date(a.createdAt)
        );
        setFiles(sortedFiles);

        // Check for auto-download parameter
        const autoDownloadOrderId = searchParams.get('download');
        if (autoDownloadOrderId) {
            const orderToDownload = filesContent.find(file => 
                file.content.oid === autoDownloadOrderId
            );
            if (orderToDownload) {
                downloadAllCroppedImages(orderToDownload.content);
            }
        }
      } catch (err) {
        setError(err);
      }
    };

    fetchFiles();
  }, [searchParams]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="orders-list w-full h-full">
      <div className="relative h-full">
        <table className="min-w-full bg-white">
          <thead className="sticky top-0 bg-white z-10">
            <tr>
              <th className="py-2 px-4 bg-gray-200">Order Number</th>
              <th className="py-2 px-4 bg-gray-200">Created At</th>
              <th className="py-2 px-4 bg-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {files.map((file, index) => (
              <tr key={index} className="border-b">
                <td className="py-2 px-4">{file.name.split('_order_number_')[0].split('/')[1]}</td>
                <td className="py-2 px-4">
                  {new Date(file.createdAt).toLocaleString()}
                </td>
                <td className="py-2 px-4">
                  <button
                    onClick={() => toggleOrderDetails(file.content)}
                    className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-700 transition duration-200 mr-2"
                  >
                    Details
                  </button>
                  <button
                    onClick={() => downloadAllCroppedImages(file.content)}
                    className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700 transition duration-200 mr-2"
                  >
                    Download All Cropped
                  </button>
                  <button
                    onClick={() => handleDeleteClick(file)}
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 transition duration-200"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showOrderDetails && (
        <Popup
          triggerText="Order Details"
          isOpen={showOrderDetails}
          togglePopup={() => toggleOrderDetails(null)}
        >
          <OrderDetails order={popupContent} />
        </Popup>
      )}

      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
            <p className="mb-4">
              Are you sure you want to delete order {deleteConfirmation.content.oid}? 
              This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setDeleteConfirmation(null)}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-200"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListOrders;