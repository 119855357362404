import React, { useState, useEffect } from 'react';
import { writeObjectToFirestore, readCollectionFromFirestore, deleteObjectFromFirestore, uploadFile } from '../firebase';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import './Settings.css';

const Settings = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [newAnnouncement, setNewAnnouncement] = useState('');
    const [popupSettings, setPopupSettings] = useState({
        title: '',
        text: '',
        imageUrl: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [announcementBarColor, setAnnouncementBarColor] = useState('#000000');
    const [buttonColor, setButtonColor] = useState(null);

    useEffect(() => {
        loadAnnouncements();
        loadPopupSettings();
        loadAnnouncementBarColor();
        loadButtonColor();
    }, []);

    const loadAnnouncements = async () => {
        const data = await readCollectionFromFirestore('announcements');
        setAnnouncements(data);
    };

    const loadPopupSettings = async () => {
        const data = await readCollectionFromFirestore('settings');
        const popupData = data.find(item => item.id === 'popup');
        if (popupData) {
            setPopupSettings(popupData);
        }
    };

    const loadAnnouncementBarColor = async () => {
        const data = await readCollectionFromFirestore('settings');
        const colorData = data.find(item => item.id === 'color');
        if (colorData && colorData.announcementBarColor) {
            setAnnouncementBarColor(colorData.announcementBarColor);
        }
    };

    const loadButtonColor = async () => {
        const data = await readCollectionFromFirestore('settings');
        const colorData = data.find(item => item.id === 'color');
        if (colorData && colorData.buttonColor) {
            setButtonColor(colorData.buttonColor);
        }
    };

    const handleAddAnnouncement = async () => {
        if (!newAnnouncement.trim()) return;
        
        const announcement = {
            text: newAnnouncement,
            timestamp: new Date().toISOString()
        };

        await writeObjectToFirestore('announcements', new Date().getTime().toString(), announcement);
        setNewAnnouncement('');
        loadAnnouncements();
    };

    const handleDeleteAnnouncement = async (id) => {
        await deleteObjectFromFirestore('announcements', id);
        loadAnnouncements();
    };

    const processImage = async (file) => {
        // First, create an image element to get dimensions
        const img = new Image();
        const imgPromise = new Promise((resolve) => {
            img.onload = () => resolve({ width: img.width, height: img.height });
        });
        img.src = URL.createObjectURL(file);
        const dimensions = await imgPromise;

        // Calculate new dimensions if needed
        let targetWidth = dimensions.width;
        let targetHeight = dimensions.height;
        
        if (dimensions.width > 1000 || dimensions.height > 1000) {
            const aspectRatio = dimensions.width / dimensions.height;
            if (dimensions.width > dimensions.height) {
                targetWidth = 1000;
                targetHeight = Math.round(1000 / aspectRatio);
            } else {
                targetHeight = 1000;
                targetWidth = Math.round(1000 * aspectRatio);
            }
        }

        // Compression options
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: Math.max(targetWidth, targetHeight),
            useWebWorker: true,
            fileType: 'image/webp'
        };

        try {
            const compressedFile = await imageCompression(file, options);
            
            // Create a new File object with .webp extension
            const convertedFile = new File(
                [compressedFile], 
                `${file.name.split('.')[0]}.webp`,
                { type: 'image/webp' }
            );
            
            return convertedFile;
        } catch (error) {
            console.error('Error processing image:', error);
            throw error;
        }
    };

    const handleFileChange = async (e) => {
        if (e.target.files[0]) {
            try {
                const processedFile = await processImage(e.target.files[0]);
                setSelectedFile(processedFile);
                
                // Create a preview URL
                const previewUrl = URL.createObjectURL(processedFile);
                setPopupSettings(prev => ({
                    ...prev,
                    imageUrl: previewUrl
                }));
            } catch (error) {
                console.error('Error handling file:', error);
                alert('Error processing image. Please try again.');
            }
        }
    };

    const handleSavePopupSettings = async () => {
        setIsUploading(true);
        try {
            let imageUrl = popupSettings.imageUrl;
            
            if (selectedFile) {
                // Generate a unique ID using UUID
                const id = uuidv4();
                const fileEnd = 'webp'; // We're converting all images to webp
                imageUrl = await uploadFile('settings', selectedFile, 'popup' + id + "." + fileEnd);
            }

            const settings = {
                ...popupSettings,
                imageUrl
            };

            await writeObjectToFirestore('settings', 'popup', settings);
            setSelectedFile(null);
            loadPopupSettings();
        } catch (error) {
            console.error('Error saving settings:', error);
            alert('Error saving settings. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleColorChange = async (e, type) => {
        const newColor = e.target.value;
        if (type === 'announcement') {
            setAnnouncementBarColor(newColor);
            await writeObjectToFirestore('settings', 'color', { 
                announcementBarColor: newColor,
                buttonColor: buttonColor 
            });
        } else if (type === 'button') {
            if (!buttonColor) {
                setButtonColor('#000000');
            }
            setButtonColor(newColor);
            await writeObjectToFirestore('settings', 'color', { 
                announcementBarColor: announcementBarColor,
                buttonColor: newColor 
            });
        }
    };

    const handleRemoveButtonColor = async () => {
        setButtonColor(null);
        await writeObjectToFirestore('settings', 'color', { 
            announcementBarColor: announcementBarColor,
            buttonColor: null 
        });
    };

    return (
        <div className="settings-container">
            <h2>Settings</h2>
            
            <section className="announcements-section">
                <h3>Announcement Bar Management</h3>
                <div className="add-announcement">
                    <input
                        type="text"
                        value={newAnnouncement}
                        onChange={(e) => setNewAnnouncement(e.target.value)}
                        placeholder="Enter new announcement"
                        className="announcement-input"
                    />
                    <button onClick={handleAddAnnouncement} className="add-btn">Add Announcement</button>
                </div>
                
                <table className="announcements-table">
                    <thead>
                        <tr>
                            <th>Announcement</th>
                            <th>Date Added</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {announcements.map((announcement) => (
                            <tr key={announcement.id}>
                                <td>{announcement.text}</td>
                                <td>{new Date(announcement.timestamp).toLocaleDateString()}</td>
                                <td>
                                    <button 
                                        onClick={() => handleDeleteAnnouncement(announcement.id)}
                                        className="delete-btn"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="form-group">
                    <label>Announcement Bar Color:</label>
                    <input
                        type="color"
                        value={announcementBarColor}
                        onChange={(e) => handleColorChange(e, 'announcement')}
                        className="color-picker"
                    />
                </div>
                <div className="form-group">
                    <label>Button Color:</label>
                    <div className="color-picker-group">
                        <input
                            type="color"
                            value={buttonColor || '#000000'}
                            onClick={() => !buttonColor && setButtonColor('#000000')}
                            onChange={(e) => handleColorChange(e, 'button')}
                            className="color-picker"
                        />
                        <button 
                            onClick={handleRemoveButtonColor}
                            className="remove-color-btn"
                        >
                            Remove Color
                        </button>
                    </div>
                </div>
            </section>

            <section className="popup-settings-section">
                <h3>Popup Settings</h3>
                <div className="popup-form">
                    <div className="form-group">
                        <label>Title:</label>
                        <input
                            type="text"
                            value={popupSettings.title}
                            onChange={(e) => setPopupSettings({...popupSettings, title: e.target.value})}
                            className="popup-input"
                        />
                    </div>
                    <div className="form-group">
                        <label>Text:</label>
                        <textarea
                            value={popupSettings.text}
                            onChange={(e) => setPopupSettings({...popupSettings, text: e.target.value})}
                            className="popup-textarea"
                        />
                    </div>
                    <div className="form-group">
                        <label>Image:</label>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept="image/*"
                            className="file-input"
                        />
                        {popupSettings.imageUrl && (
                            <img 
                                src={popupSettings.imageUrl} 
                                alt="Current popup" 
                                className="preview-image"
                            />
                        )}
                    </div>
                    <button 
                        onClick={handleSavePopupSettings} 
                        className="save-btn"
                        disabled={isUploading}
                    >
                        {isUploading ? 'Saving...' : 'Save Popup Settings'}
                    </button>
                </div>
            </section>
        </div>
    );
};

export default Settings; 